<template>
	<!-- Modal Button (Assuming you want to open the modal using a button) -->
	<button
		type="button"
		role="button"
		class="btn btn-primary btn-sm shadow-0 create-dialog-btn btn-rounded"
		@click.stop="(dialog = !dialog), (files = [])"
	>
		<span class="create-btn-text">
			<FontAwesomeIcon :icon="lookupIcon('cloud-arrow-up', 'fas')" />
			Upload
		</span>
	</button>

	<!-- MDB Modal -->
	<MDBModal v-model="dialog" size="xl">
		<MDBModalHeader>
			<MDBModalTitle>{{
				$t("dashboard.assets.create_form_title")
			}}</MDBModalTitle>
		</MDBModalHeader>
		<MDBModalBody>
			<MDBContainer>
				<div
					class="dropzone-container"
					@dragover="dragover"
					@dragleave="dragleave"
					@drop="drop"
				>
					<input
						id="fileInput"
						ref="file"
						type="file"
						multiple
						name="file"
						class="hidden-input"
						accept=".pdf,.jpg,.jpeg,.png"
						@change="onChange"
					/>
					<label for="fileInput" class="file-label">
						<div v-if="isDragging">Release to drop files here.</div>
						<div v-else>Drop files here or <u>click here</u> to upload.</div>
					</label>
					<div v-if="files.length" class="preview-container mt-4 row">
						<MDBAccordion v-model="accordianOpen" class="container" stay-open>
							<MDBAccordionItem
								v-for="file in files"
								:key="file.name"
								class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 d-block"
								:collapse-id="file.id"
								:header-title="file.name"
							>
								<div class="row">
									<div class="col-1">
										<MDBBtn
											class="btn-danger btn-sm ml-2"
											type="button"
											title="Remove file"
											@click="remove(files.indexOf(file))"
										>
											&times;
										</MDBBtn>
									</div>
									<div class="col-3 d-block">
										<img
											class="preview-img"
											:src="generateThumbnail(file.file)"
										/>
									</div>
									<div class="col-8">
										<MDBInput
											v-model="file.name"
											label="Rename File"
											:form-outline="false"
											wrapper-class="form-floating"
										/>
										<div><b>Size:</b> {{ file.file.size / 1000 }} KB</div>
									</div>
								</div>
							</MDBAccordionItem>
						</MDBAccordion>
					</div>
				</div>
			</MDBContainer>
		</MDBModalBody>
		<MDBModalFooter>
			<MDBBtn color="secondaryz" @click="dialog = false">Cancel</MDBBtn>
			<MDBBtn color="primary" @click="onSubmit">Upload</MDBBtn>
		</MDBModalFooter>
	</MDBModal>
</template>

<script>
import { useAssetStore } from "@/Store/assetStore"
import { useProjectStore } from "@/Store/projectStore"
import { router } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

import {
	MDBAccordion,
	MDBAccordionItem,
	MDBBtn,
	MDBCol,
	MDBContainer,
	MDBInput,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBRow,
} from "mdb-vue-ui-kit"
import axios from "axios"
import route from "ziggy-js"
import { storeToRefs } from "pinia"

export default {
	components: {
		MDBBtn,
		MDBModal,
		MDBModalHeader,
		MDBModalTitle,
		MDBModalBody,
		MDBContainer,
		MDBAccordion,
		MDBAccordionItem,
		MDBInput,
		MDBModalFooter,
		FontAwesomeIcon,
	},
	props: {
		formDefinition: {
			type: Object,
			default: () => {
				return {}
			},
		},
		size: {
			type: String,
			default: "xl",
		},
		showOnMounted: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["success"],
	data() {
		return {
			dialog: false,
			isDragging: false,
			files: [],
			accordianOpen: [],
			lookupIcon: lookupIcon,
		}
	},
	methods: {
		onSubmit() {
			const formData = new FormData()
			let uploadRoute = ""
			if (this.formDefinition?.create) {
				uploadRoute = route(
					this.formDefinition.create.route,
					this.formDefinition.create.params,
				)
				let assetFileName = "asset[]"
				if (this.formDefinition.assetFileName) {
					assetFileName = this.formDefinition.assetFileName + "[]"
				}
				this.files.forEach((file) => {
					formData.append(assetFileName, file.file)
				})
			} else {
				const projectStore = useProjectStore()
				const project_id = projectStore.getActiveProject.slug
				const assetStore = useAssetStore()

				this.files.forEach((file) => {
					formData.append("asset[]", file.file)
				})
				uploadRoute = `/projects/${project_id}/assets`
			}
			const toast = useToast()

			router.post(uploadRoute, formData, {
				onSuccess: (event) => {
					toast.success("Successfully uploaded assets")
					this.$emit("success")
				},
				onError: (errors) => {
					toast.error(Object.values(errors).join("\n"))
				},
			})

			this.files = []
			this.dialog = false
		},
		onChange() {
			this.files = []
			Array.from(this.$refs.file.files).forEach((file, index) => {
				this.files.push({
					file: file,
					name: file.name,
					id: "file-upload-" + index.toString(),
				})
			})
			this.accordianOpen = this.files[0].id || null
			// this.files = [...this.$refs.file.files];
		},
		generateThumbnail(file) {
			const fileSrc = URL.createObjectURL(file)
			setTimeout(() => {
				URL.revokeObjectURL(fileSrc)
			}, 1000)
			return fileSrc
		},
		remove(i) {
			this.files.splice(i, 1)
		},
		dragover(e) {
			e.preventDefault()
			this.isDragging = true
		},
		dragleave() {
			this.isDragging = false
		},
		drop(e) {
			e.preventDefault()
			this.$refs.file.files = e.dataTransfer.files
			this.onChange()
			this.isDragging = false
		},
	},
}
</script>

<style lang="css" scoped>
.main {
	display: flex;
	flex-grow: 1;
	align-items: center;
	height: 100vh;
	justify-content: center;
	text-align: center;
}
.dropzone-container {
	padding: 4rem;
	border: 1px solid #e2e8f0;
}
.hidden-input {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
}
.file-label {
	font-size: 20px;
	display: block;
	cursor: pointer;
}
.preview-container {
	display: flex;
	margin-top: 2rem;
}
.preview-card {
	display: flex;
	border: 1px solid #a2a2a2;
	padding: 5px;
	margin-left: 5px;
}
.preview-img {
	width: 100%;
	height: auto;
	border-radius: 5px;
	border: 1px solid #a2a2a2;
	background-color: #a2a2a2;
}
@media (max-width: 1000px) {
	:deep(.modal-dialog.modal-xl) {
		--mdb-modal-width: 80%;
	}
}
</style>
